import 'bootstrap';
import css from "./styles.scss";

function requireAll(r) {
  r.keys().forEach(r);
}

requireAll(require.context('../images/sprites/', true, /\.svg$/));

document.querySelectorAll('[data-sprite-url]').forEach(function(el){
    fetch(el.dataset.spriteUrl).then(res => {
        return res.text();
    }).then(data => {
        el.innerHTML = data;
    });    
})
/*
const navbar = document.querySelector("#mainMenu");
const spot = document.querySelector(".spot");
const handleScroll = (entries) => {
    const spotIsVisible = entries[0].isIntersecting;
    if (spotIsVisible) navbar.classList.remove("fixed-top");
    else navbar.classList.add("fixed-top");
}
const options = {
    root : null,
    rootMargin: "0px",
    threshold : 0,
}

const observer = new IntersectionObserver(handleScroll, options);
observer.observe(spot);
*/